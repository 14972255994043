<template>
  <CCard>
    <CCardHeader>
      <CIcon name="cil-input-power" />
      {{ $t('forms.transl128')}}
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol sm="12">
          <CForm>
            <CRow>
              <CCol md="6">
                <CInput
                  maxlength="255"
                  :label="$i18n.translate('forms.transl129')"
                  v-model.trim="$v.form.name.$model"
                  :isValid="checkIfValid('name')"
                  :placeholder="$i18n.translate('forms.transl129')"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
              <CCol md="6">
                <CInput
                  maxlength="255"
                  :label="$i18n.translate('forms.transl130')"
                  v-model.trim="$v.form.surname.$model"
                  :isValid="checkIfValid('surname')"
                  :placeholder="$i18n.translate('forms.transl130')"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
            </CRow>

            <CInput
              maxlength="255"
              :label="$i18n.translate('forms.transl131')"
              v-model.trim="$v.form.phone.$model"
              :placeholder="$i18n.translate('forms.transl131')"
              :isValid="checkIfValid('phone')"
              :invalidFeedback="$i18n.translate('forms.transl30')"
            />

            <CInput
              :label="$i18n.translate('forms.transl132')"
              v-model.trim="$v.form.email.$model"
              plaintext
            />

            <CRow>
              <CCol md="6">
                <CInput
                  v-model.trim="$v.form.password.$model"
                  :label="$i18n.translate('forms.transl36')"
                  type="password"
                  :placeholder="$i18n.translate('forms.transl36')"
                  :invalidFeedback="$i18n.translate('forms.transl31')"
                />
              </CCol>
              <CCol md="6">
                <CInput
                  v-model.trim="$v.form.password2.$model"
                  :label="$i18n.translate('forms.transl36')"
                  type="password"
                  :placeholder="$i18n.translate('forms.transl36')"
                  :invalidFeedback="$i18n.translate('forms.transl32')"
                />
              </CCol>
            </CRow>

            <CSelect id="status"
                     :label="$i18n.translate('forms.transl96')"
                     :value.sync="$v.form.status.$model"
                     :options="statuses"
                     :isValid="checkIfValid('status')"
            >
            </CSelect>

            <CTextarea
              :label="$i18n.translate('forms.transl133')"
              :placeholder="$i18n.translate('forms.transl133')"
              rows="9"
              v-model.trim="$v.form.description.$model"
            />

            <CButton
              color="primary"
              :disabled="$v.form.$invalid"
              @click="submit"
            >
              {{ $i18n.translate('forms.transl52') }}
            </CButton>
            <!--            <CButton-->
            <!--              class="ml-1"-->
            <!--              color="success"-->
            <!--              :disabled="$v.form.$invalid"-->
            <!--              @click="validate"-->
            <!--            >-->
            <!--              Validate-->
            <!--            </CButton>-->
            <!--            <CButton-->
            <!--              class="ml-1"-->
            <!--              color="danger"-->
            <!--              :disabled="!isDirty"-->
            <!--              @click="reset"-->
            <!--            >-->
            <!--              Reset-->
            <!--            </CButton>-->
            <CButton
  class="ml-1"
  color="success"
  @click="goBack"
>
  {{ $i18n.translate('forms.transl53') }}
</CButton>

          </CForm>
          <br />
        </CCol>
      </CRow>
    </CCardBody >
  </CCard >
</template >

<script>
import axios from 'axios';
import Vue from 'vue';
import CKEditor from 'ckeditor4-vue';
import Swal from "sweetalert2";
import {validationMixin} from "vuelidate"
import {required, minLength, email, sameAs, helpers} from "vuelidate/lib/validators"

export default {
  data() {
    return {
      form: {
        name: null,
        surname: null,
        phone: null,
        email: null,
        status: null,
        description: null,
        password: null,
        password2: null,
      },
      statuses: [],
    }
  },
  computed: {
    formString() {
      return JSON.stringify(this.form, null, 4)
    },
    isValid() {
      return !this.$v.form.$invalid
    },
    isDirty() {
      return this.$v.form.$anyDirty
    },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: 255
      },
      surname: {
        required,
        minLength: minLength(3),
        maxLength: 255
      },
      phone: {
        required,
        minLength: minLength(3),
        maxLength: 255
      },
      email: {
        maxLength: 255
      },
      status: {
      },
      description: {
      },
      password: {
        // minLength: minLength(8),
        // maxLength: 255,
        // strongPass: helpers.regex('strongPass', /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/)
      },
      password2: {
        // maxLength: 255,
        // sameAsPassword: sameAs("password")
      }
    }
  },
  methods: {
    goBack() {
      this.$router.replace({ path: '/administrators' })
    },
    checkIfValid(fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      return !(field.$invalid || field.$model === '')
    },
    store() {
      let self = this;
      axios.post(this.$apiAdress + '/api/administrators/' + self.$route.params.id + '?token=' + localStorage.getItem("api_token")+ '&s_lng=' + self.$i18n.locale(),
        {
          _method: 'PUT',
          name: self.form.name,
          surname: self.form.surname,
          phone: self.form.phone,
          status: self.form.status,
          description: self.form.description,
          password: self.form.password,
          password2: self.form.password2,
        })
        .then(function (response) {
          if (response.data.status == 'success') {
            Swal.fire(
              self.$i18n.translate('forms.transl13'),
self.$i18n.translate('forms.transl62'),
              'success'
            ).then(function () {
              self.$router.push('/administrators');
            });
          } else {
            Swal.fire(
              self.$i18n.translate('forms.transl64'),
              response,
              'error'
            )
          }
        }).catch(function (error) {
        if (error.response.data.message != '') {
          let errorDetails = ""
          for (let key in error.response.data.errors) {
            errorDetails += `${error.response.data.errors[key]}<br/>`
          }
          Swal.fire(
            self.$i18n.translate('forms.transl64'),
            errorDetails,
            'error'
          )
          window.scrollTo({top: 0});
        } else {
          Swal.fire(
            self.$i18n.translate('forms.transl64'),
            error,
            'error'
          )
          self.$router.push({path: 'login'});
        }
      });
    },

    submit() {
      this.validate();
      if(!this.$v.form.$invalid) {
        this.store();
      }
    },

    validate() {
      this.$v.$touch()
    },

    reset() {
      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },

    getEmptyForm() {
      return {
        name: null,
        surname: null,
        phone: null,
        description: null,
        password: null,
        password2: null,
        status: null,
      }
    }
  },
  created: function () {
    let self = this;
    axios.get(this.$apiAdress + '/api/administrators/' + self.$route.params.id + '/edit?token=' + localStorage.getItem("api_token"))
      .then(function (response) {
        self.statuses = response.data.statuses;
        self.form.name= response.data.record.name;
        self.form.surname= response.data.record.surname;
        self.form.phone= response.data.record.phone;
        self.form.email= response.data.record.email;
        self.form.description= response.data.record.description;
        self.form.password = response.data.record.password ;
        self.form.password2 = response.data.record.password2 ;
        self.form.status= response.data.record.status;
        self.validate();
      }).catch(function (error) {
      console.log(error);
      self.$router.push({path: '/login'});
    });
  }
}
</script>
